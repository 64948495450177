body {
    .ui-messages {
        padding: $messagesPadding;
        margin: $messagesMargin;
        
        &.ui-messages-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;
            
            .ui-messages-icon {
                color: $infoMessageIconColor;
            }
            
            .ui-messages-close {
                color:  $infoMessageIconColor;
            }
        }

        &.ui-messages-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;
            
            .ui-messages-icon {
                color: $successMessageIconColor;
            }
            
            .ui-messages-close {
                color:  $successMessageIconColor;
            }
        }

        &.ui-messages-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;
            
            .ui-messages-icon {
                color: $warnMessageIconColor;
            }
            
            .ui-messages-close {
                color:  $warnMessageIconColor;
            }
        }

        &.ui-messages-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;
            
            .ui-messages-icon {
                color: $errorMessageIconColor;
            }
            
            .ui-messages-close {
                color:  $errorMessageIconColor;
            }
        }
        
        .ui-messages-close {
            top: .25em;
            right: .5em;
            font-size: $messageCloseIconFontSize;
        }

        .ui-messages-icon {
            font-size: $messagesIconFontSize;
        }
    }

    .ui-message {
        padding: $messagePadding;
        margin: $messageMargin;
        
        &.ui-message-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;
            
            .ui-message-icon {
                color: $infoMessageIconColor;
            }
        }

        &.ui-message-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;
            
            .ui-message-icon {
                color: $successMessageIconColor;
            }
        }

        &.ui-message-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;
            
            .ui-message-icon {
                color: $warnMessageIconColor;
            }
        }

        &.ui-message-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;
            
            .ui-message-icon {
                color: $errorMessageIconColor;
            }
        }

        .ui-message-icon {
            font-size: $messageIconFontSize;
        }

        .ui-message-text {
            font-size: $messageTextFontSize;
        }
    }
        
    .ui-growl {
        top: $growlTopLocation;
        
        .ui-growl-item-container {
            margin: $growlMargin;
            @include opacity($growlOpacity);
            @include shadow($growlShadow);

            .ui-growl-item {
                padding: $growlPadding;

                .ui-growl-message {
                    margin: $growlMessageTextMargin;
                }
    
                .ui-growl-image {
                    font-size: $growlIconFontSize;
                }
            }
            
            &.ui-growl-message-info {
                background-color: $infoMessageBgColor;
                border: $infoMessageBorder;
                color: $infoMessageTextColor;
                
                .ui-growl-image {
                    color: $infoMessageIconColor;
                }
            }  

            &.ui-growl-message-success {
                background-color: $successMessageBgColor;
                border: $successMessageBorder;
                color: $successMessageTextColor;
                
                .ui-growl-image {
                    color: $successMessageIconColor;
                }
            }  
            
            &.ui-growl-message-warn {
                background-color: $warnMessageBgColor;
                border: $warnMessageBorder;
                color: $warnMessageTextColor;
                
                .ui-growl-image {
                    color: $warnMessageIconColor;
                }
            }
            
            &.ui-growl-message-error {
                background-color: $errorMessageBgColor;
                border: $errorMessageBorder;
                color: $errorMessageTextColor;
                
                .ui-growl-image {
                    color: $errorMessageIconColor;
                }
            }
        
        }
    }

    .ui-toast {
        .ui-toast-message {
            @include shadow($toastShadow);
            margin: $toastMessageMargin;

            &.ui-toast-message-info {
                background-color: $infoMessageBgColor;
                border: $infoMessageBorder;
                color: $infoMessageTextColor;
                
                .ui-toast-close-icon {
                    color: $infoMessageTextColor;
                }
            }
    
            &.ui-toast-message-success {
                background-color: $successMessageBgColor;
                border: $successMessageBorder;
                color: $successMessageTextColor;
                
                .ui-toast-close-icon {
                    color: $successMessageIconColor;
                }
            }
    
            &.ui-toast-message-warn {
                background-color: $warnMessageBgColor;
                border: $warnMessageBorder;
                color: $warnMessageTextColor;
                
                .ui-toast-close-icon {
                    color: $warnMessageTextColor;
                }
            }
    
            &.ui-toast-message-error {
                background-color: $errorMessageBgColor;
                border: $errorMessageBorder;
                color: $errorMessageTextColor;
                
                .ui-toast-close-icon {
                    color: $errorMessageTextColor;
                }
            }
        }
    }
    
}