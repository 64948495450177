/*Layout*/
// hamburger menu button padding
.btn-sidebar button{
	padding: .25em .5em;
}

.btn-sidebar {
	float: right;
	margin-top: 0.25em;
	transition: $app-header-margin-transition;
}

@media (min-width: 768px) {
	#app-sidebar {
		display: none;
	}
	.btn-sidebar {
		display: none;
	}
}

@media (min-width: 480px) {
	.btn-sidebar {
		margin-top: 0.5em;
		transition: $app-header-margin-transition;
	}
}

/*Theme*/
// hamburger menu properties
.btn-sidebar button{
	background: none;
	color: $ford-blue;
}
