/*Layout*/
// following two classes belongs to export buttons
.export-btn-group {
	float: right;
}
body .ui-button {
	margin: .125em;
	border: none;
}

/*Theme*/
// select-all/select-none
.ui-button-link {
	background:none!important;
	border:none;
	outline: none;
	cursor:pointer;
	color: $CTA-dark-cyan;
	font-family: $fontFamilyDefault;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
	font-size: 16px;
}

i.button-icon {
	padding-right: 0.3em;
	margin-left: -0.5em;
}