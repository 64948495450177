/*Layout*/

/* primeng tables are fixed cell widths. gross. override back to default */
// data table layout
.ui-datatable table {
	table-layout: auto;
}

// datatable layout property
.ui-table table {
	table-layout: auto;
}

// checkbox alignment
body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td  {
	padding: 0.571em 0.350em;
}


