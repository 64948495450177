/* Add your customizations of theme here */
@import 'buttons';
@import 'content';
@import 'datatable';
@import 'form';
@import 'header';
@import 'panels';
@import 'responsive';
@import 'sidebar';
@import 'tables';
@import 'type';
@import 'dropdown';
@import 'fonts';
@import '_extensions';