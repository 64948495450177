/*Layout*/
dl, h5 {
	margin: 0;
	padding: 0;
}
dd {
	margin-left: 0;
	margin-top: .5rem;
	word-wrap: break-word;
}

.ui-panel-titlebar a {
	color: $CTA-dark-cyan;
}


body .ui-dataview {
	.ui-dataview-header {
		margin-top: 0.5em;
	}
	.ui-dataview-content{
		.ui-panel-titlebar{
			margin: 0.5em 0.5em 0 0.5em !important;
		}
		.ui-panel-content{
			margin: 0 0.5em 0 0.5em !important;
		}
		.ui-panel-footer{
			margin: 0 0.5em 0.5em 0.5em !important;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}
/*Theme*/
dt {
	font-weight: $font-weight-bold;
}