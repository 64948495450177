label[class^='ui-g']{
	padding-bottom: 0;
}
textarea{
	width: 99%;
}
.ui-calendar .ui-calendar-button{
	margin: 0 .125px 0 0;
}


.ui-autocomplete-input-token, .ui-autocomplete-input-token input{
	width: 100%;
	border: none;
}

.ui-dropdown{
	 width:auto !important; //Here to override PrimeNG computed width which for some reason is undersized
 }