@charset "UTF-8";
/* override PrimeNG theme font family */
/*Font paths with obfuscated names from WebType*/
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*ui component Colors coming from the WCAG 2.0 AA Accessibility Compliance guide from GTB*/
/* Backgrounds */
/* Blue */
/* Gray */
/* Text and Icons */
/* CTA Colors */
/* Text Dark */
/* Alerts */
/*Responsive*/
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body .ui-widget {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-decoration: none;
}
body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body a {
  color: #092A46;
  text-decoration: none;
}
body a:hover {
  color: #0C1218;
}
body a:active {
  color: #0276B3;
}
body .ui-helper-reset {
  line-height: normal;
}
body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
body .pi {
  font-size: 1.25em;
}

body {
  /* Validations */
}
body .ui-inputtext {
  font-size: 14px;
  color: #333333;
  background: #FFFFFF;
  padding: 0.429em;
  border: 1px solid #A7A7A7;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #0C1218;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: #2096CD;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-chkbox .ui-chkbox-box {
  border: 1px solid #A7A7A7;
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
  border-color: #0C1218;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  border-color: #2096CD;
  background-color: #FFFFFF;
  color: #2096CD;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #2096CD;
  background-color: #2096CD;
  color: #FFFFFF;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #092A46;
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-focus {
  border-color: #2096CD;
  background-color: #2096CD;
  color: #FFFFFF;
}
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
}
body .ui-chkbox-label {
  margin: 0 0 0 0.5em;
}
body .ui-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-radiobutton .ui-radiobutton-box {
  border: 1px solid #A7A7A7;
  background-color: #FFFFFF;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #0C1218;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  border-color: #2096CD;
  background-color: #FFFFFF;
  color: #2096CD;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #2096CD;
  background-color: #2096CD;
  color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #092A46;
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-focus {
  border-color: #2096CD;
  background-color: #2096CD;
  color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
  display: none;
}
body .ui-radiobutton-label {
  margin: 0 0 0 0.5em;
}
body .ui-inputswitch {
  width: 3em;
  height: 1.75em;
}
body .ui-inputswitch .ui-inputswitch-slider {
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 30px;
  background: #FAFAFA;
}
body .ui-inputswitch .ui-inputswitch-slider:before {
  background-color: #FFFFFF;
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}
body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #A7A7A7;
}
body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #A7A7A7;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #2096CD;
}
body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #0276B3;
}
body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #092A46;
}
body .ui-autocomplete .ui-autocomplete-input {
  padding: 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #0C1218;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  border-color: #2096CD;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: #333333;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #0276B3;
  color: #FFFFFF;
}
body .ui-autocomplete-panel {
  padding: 0;
  border: 1px solid #FAFAFA;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-autocomplete-panel .ui-autocomplete-items {
  padding: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #0276B3;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #A7A7A7;
  color: #333333;
}
body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
  width: 2.357em;
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-multiple.ui-autocomplete-dd .ui-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body .ui-chips > ul.ui-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
  border-color: #0C1218;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  border-color: #2096CD;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token {
  padding: 0.2145em 0;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #333333;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #0276B3;
  color: #FFFFFF;
}
body .ui-dropdown {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #0C1218;
}
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  border-color: #2096CD;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-dropdown .ui-dropdown-label {
  padding-right: 2em;
}
body .ui-dropdown .ui-dropdown-trigger {
  background-color: #FFFFFF;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #555555;
}
body .ui-dropdown .ui-dropdown-clear-icon {
  color: #555555;
}
body .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
  padding-right: 4em;
}
body .ui-dropdown-panel {
  padding: 0;
  border: 1px solid #FAFAFA;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  right: 1.357em;
  color: #2096CD;
}
body .ui-dropdown-panel .ui-dropdown-items {
  padding: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #FFFFFF;
  background-color: #0276B3;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #FAFAFA;
}
body .ui-multiselect {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #0C1218;
}
body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
  border-color: #2096CD;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-multiselect .ui-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
  color: #333333;
}
body .ui-multiselect .ui-multiselect-trigger {
  background-color: #FFFFFF;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #555555;
}
body .ui-multiselect-panel {
  padding: 0;
  border: 1px solid #FAFAFA;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-multiselect-panel .ui-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  color: #2096CD;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-chkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
  color: #555555;
  top: 50%;
  margin-top: -0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:hover {
  color: #333333;
}
body .ui-multiselect-panel .ui-multiselect-items {
  padding: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #0276B3;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #FAFAFA;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box {
  cursor: auto;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box:hover {
  border: 1px solid #A7A7A7;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box.ui-state-active:hover {
  border-color: #2096CD;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox {
  padding: 0;
  min-width: 12em;
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
}
body .ui-listbox .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: #2096CD;
}
body .ui-listbox .ui-listbox-list {
  background-color: #FFFFFF;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #0276B3;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #333333;
  background-color: #FAFAFA;
}
body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: 1px solid #A7A7A7;
}
body .ui-listbox .ui-listbox-footer {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-top: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
}
body .ui-editor-container .ui-editor-toolbar {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
}
body .ui-editor-container .ui-editor-content {
  border: 1px solid #A7A7A7;
}
body .ui-editor-container .ui-editor-content .ql-editor {
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: #24292F;
}
body .ui-editor-container .ql-stroke {
  stroke: #24292F;
}
body .ui-editor-container .ql-picker-label {
  color: #24292F;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover,
body .ui-editor-container .ql-snow .ql-toolbar button:hover,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #0276B3;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #0276B3;
}
body .ui-rating a {
  text-align: center;
  display: inline-block;
  color: #333333;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-rating a.ui-rating-cancel {
  color: #37A9E8;
}
body .ui-rating a .ui-rating-icon {
  font-size: 20px;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:hover {
  color: #2096CD;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a.ui-rating-cancel:hover {
  color: #2096CD;
}
body .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-spinner .ui-spinner-button.ui-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button.ui-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}
body .ui-fluid .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-fluid .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  left: 50%;
}
body .ui-slider {
  background-color: #FAFAFA;
  border: 0 none;
}
body .ui-slider.ui-slider-horizontal {
  height: 0.286em;
}
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  margin-top: -0.5715em;
}
body .ui-slider.ui-slider-vertical {
  width: 0.286em;
}
body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: 50%;
  margin-left: -0.5715em;
}
body .ui-slider .ui-slider-handle {
  height: 1.143em;
  width: 1.143em;
  background-color: #FFFFFF;
  border: 2px solid #555555;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-slider .ui-slider-range {
  background-color: #2096CD;
}
body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  background-color: 2px solid #555555;
  border: 2px solid #2096CD;
}
body .ui-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
}
body .ui-datepicker:not(.ui-datepicker-inline) {
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover {
  color: #2096CD;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  color: #2096CD;
}
body .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: #A7A7A7;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
  background-color: #A7A7A7;
}
body .ui-datepicker .ui-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: #FFFFFF;
  color: #333333;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #A7A7A7;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
  margin-top: -0.35em;
  margin-bottom: 0;
}
body .ui-datepicker table {
  font-size: 14px;
  margin: 0.857em 0 0 0;
}
body .ui-datepicker table th {
  padding: 0.5em;
}
body .ui-datepicker table td {
  padding: 0.5em;
}
body .ui-datepicker table td > a, body .ui-datepicker table td > span {
  display: block;
  text-align: center;
  color: #333333;
  padding: 0.5em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-datepicker table td > a.ui-state-active, body .ui-datepicker table td > span.ui-state-active {
  color: #FFFFFF;
  background-color: #2096CD;
}
body .ui-datepicker table td > a {
  cursor: pointer;
}
body .ui-datepicker table td.ui-datepicker-today > a, body .ui-datepicker table td.ui-datepicker-today > span {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-datepicker table td.ui-datepicker-today > a.ui-state-active, body .ui-datepicker table td.ui-datepicker-today > span.ui-state-active {
  color: #FFFFFF;
  background-color: #2096CD;
}
body .ui-datepicker table td.ui-datepicker-weekheader, body .ui-datepicker table td.ui-datepicker-weeknumber {
  border-right: 1px solid #A7A7A7;
}
body .ui-datepicker .ui-datepicker-buttonbar {
  border-top: 1px solid #A7A7A7;
}
body .ui-datepicker .ui-timepicker {
  border: 0 none;
  border-top: 1px solid #555555;
  padding: 0.857em;
}
body .ui-datepicker .ui-timepicker a {
  color: #333333;
  font-size: 1.286em;
}
body .ui-datepicker .ui-timepicker a:hover {
  color: #2096CD;
}
body .ui-datepicker .ui-timepicker span {
  font-size: 1.286em;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month {
  color: #333333;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month.ui-state-active {
  color: #FFFFFF;
  background-color: #2096CD;
}
body .ui-datepicker.ui-datepicker-timeonly {
  padding: 0;
}
body .ui-datepicker.ui-datepicker-timeonly .ui-timepicker {
  border-top: 0 none;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
  border-right: 1px solid #A7A7A7;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:first-child {
  padding-left: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error), body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 2.357em;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-fluid .ui-calendar.ui-calendar-w-btn input.ui-inputtext {
  width: calc(100% - 2.357em);
}
body .ui-fileupload .ui-fileupload-buttonbar {
  background-color: #FAFAFA;
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  color: #24292F;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
  margin-right: 8px;
}
body .ui-fileupload .ui-fileupload-content {
  background-color: #FAFAFA;
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  color: #333333;
}
body .ui-fileupload .ui-progressbar {
  top: 0;
}
body .ui-fileupload-choose:not(.ui-state-disabled):hover {
  background-color: #092A46;
  color: #FFFFFF;
  border-color: #0C1218;
}
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #0C1218;
  color: #FFFFFF;
  border-color: #092A46;
}
body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  outline-offset: 0px;
}
body .ui-password-panel {
  padding: 12px;
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-inputgroup .ui-inputgroup-addon {
  border-color: #A7A7A7;
  background-color: #FAFAFA;
  color: #555555;
  padding: 0.429em;
  min-width: 2em;
}
body .ui-inputgroup .ui-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox .ui-chkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton .ui-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-fluid .ui-inputgroup .ui-button {
  width: auto;
}
body .ui-fluid .ui-inputgroup .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body ::-webkit-input-placeholder {
  color: #555555;
}
body :-moz-placeholder {
  color: #555555;
}
body ::-moz-placeholder {
  color: #555555;
}
body :-ms-input-placeholder {
  color: #555555;
}
body .ui-inputtext.ng-dirty.ng-invalid,
body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
body p-selectbutton.ng-dirty.ng-invalid .ui-button,
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border: 1px solid #D92E14;
}

body .ui-button {
  margin: 0;
  color: #FFFFFF;
  background-color: #0276B3;
  border: 1px solid #092A46;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-button:enabled:hover {
  background-color: #092A46;
  color: #FFFFFF;
  border-color: #0C1218;
}
body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-button:enabled:active {
  background-color: #0C1218;
  color: #FFFFFF;
  border-color: #092A46;
}
body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.429em 1em;
}
body .ui-button.ui-button-text-icon-left .ui-button-text {
  padding: 0.429em 1em 0.429em 2em;
}
body .ui-button.ui-button-text-icon-right .ui-button-text {
  padding: 0.429em 2em 0.429em 1em;
}
body .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0.429em;
}
body .ui-button.ui-button-raised {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
body .ui-button.ui-button-rounded {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
body .ui-fluid .ui-button-icon-only {
  width: 2.357em;
}
body .ui-togglebutton {
  background-color: #FFFFFF;
  border: 1px solid #FAFAFA;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-togglebutton .ui-button-icon-left {
  color: #555555;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #FAFAFA;
  border-color: #FAFAFA;
  color: #333333;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #0C1218;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #FAFAFA;
  border-color: #FAFAFA;
  color: #333333;
  outline: 0 none;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #0C1218;
}
body .ui-togglebutton.ui-state-active {
  background-color: #2096CD;
  border-color: #2096CD;
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active:hover {
  background-color: #0276B3;
  border-color: #0276B3;
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active:hover .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active.ui-state-focus {
  background-color: #092A46;
  border-color: #092A46;
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button {
  background-color: #FFFFFF;
  border: 1px solid #FAFAFA;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-selectbutton .ui-button .ui-button-icon-left {
  color: #555555;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #FAFAFA;
  border-color: #FAFAFA;
  color: #333333;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #0C1218;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  background-color: #FAFAFA;
  border-color: #FAFAFA;
  color: #333333;
  outline: 0 none;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
  color: #0C1218;
}
body .ui-selectbutton .ui-button.ui-state-active {
  background-color: #2096CD;
  border-color: #2096CD;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover {
  background-color: #0276B3;
  border-color: #0276B3;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus {
  background-color: #092A46;
  border-color: #092A46;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-selectbutton .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
@media (max-width: 640px) {
  body .ui-buttonset:not(.ui-splitbutton) .ui-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-menu {
  min-width: 100%;
}
body .ui-button.ui-state-default.ui-button-secondary, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default {
  color: #333333;
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:hover, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:hover {
  background-color: #A7A7A7;
  color: #333333;
  border-color: #555555;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:active, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:active {
  background-color: #A7A7A7;
  color: #333333;
  border-color: #555555;
}
body .ui-button.ui-state-default.ui-button-info, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #2096CD;
  border: 1px solid #0276B3;
}
body .ui-button.ui-state-default.ui-button-info:enabled:hover, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:hover {
  background-color: #0276B3;
  color: #FFFFFF;
  border-color: #2096CD;
}
body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-button.ui-state-default.ui-button-info:enabled:active, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:active {
  background-color: #0276B3;
  color: #FFFFFF;
  border-color: #2096CD;
}
body .ui-button.ui-state-default.ui-button-success, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #15830B;
  border: 1px solid #3FA51C;
}
body .ui-button.ui-state-default.ui-button-success:enabled:hover, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:hover {
  background-color: #0d5407;
  color: #FFFFFF;
  border-color: #15830B;
}
body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #5ef050;
  -moz-box-shadow: 0 0 0 0.2em #5ef050;
  box-shadow: 0 0 0 0.2em #5ef050;
}
body .ui-button.ui-state-default.ui-button-success:enabled:active, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:active {
  background-color: #062503;
  color: #FFFFFF;
  border-color: #15830B;
}
body .ui-button.ui-state-default.ui-button-warning, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #B85500;
  border: 1px solid #C88310;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:hover, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:hover {
  background-color: #853d00;
  color: #FFFFFF;
  border-color: #B85500;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #ffb06c;
  -moz-box-shadow: 0 0 0 0.2em #ffb06c;
  box-shadow: 0 0 0 0.2em #ffb06c;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:active, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:active {
  background-color: #522600;
  color: #FFFFFF;
  border-color: #B85500;
}
body .ui-button.ui-state-default.ui-button-danger, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #D92E14;
  border: 1px solid #FF5724;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:hover, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:hover {
  background-color: #aa2410;
  color: #FFFFFF;
  border-color: #D92E14;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #f7b3a8;
  -moz-box-shadow: 0 0 0 0.2em #f7b3a8;
  box-shadow: 0 0 0 0.2em #f7b3a8;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:active, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:active {
  background-color: #7c1a0b;
  color: #FFFFFF;
  border-color: #D92E14;
}

body .ui-widget-content p {
  line-height: 1.5;
  margin: 0;
}
body .ui-panel {
  padding: 0;
  border: 0 none;
}
body .ui-panel .ui-panel-titlebar {
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  background-color: #FAFAFA;
  color: #24292F;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-panel .ui-panel-titlebar .ui-panel-title {
  vertical-align: middle;
  font-weight: 400;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: 14px;
  color: #24292F;
  border: 1px solid transparent;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #333333;
}
body .ui-panel .ui-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-panel .ui-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FFFFFF;
  color: #333333;
  border-top: 0 none;
  margin: 0;
}
body .ui-fieldset {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-fieldset .ui-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  color: #24292F;
  background-color: #FAFAFA;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #24292F;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
  padding: 0;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  color: #333333;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover .ui-fieldset-toggler {
  color: #333333;
}
body .ui-fieldset .ui-fieldset-content {
  padding: 0;
}
body .ui-accordion .ui-accordion-header {
  margin-bottom: 2px;
}
body .ui-accordion .ui-accordion-header a {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  color: #24292F;
  background-color: #FAFAFA;
  color: #24292F;
  font-weight: 400;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  color: #24292F;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
  color: #333333;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #092A46;
  border: 1px solid #555555;
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
  border: 1px solid #555555;
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #24292F;
  padding: 0.571em 1em;
  font-weight: 400;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
  margin-right: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
  margin-left: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
  color: #24292F;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #092A46;
  border: 1px solid #555555;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border: 1px solid #555555;
  background-color: #092A46;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview .ui-tabview-panels {
  background-color: #FAFAFA;
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  color: #333333;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  padding: 0;
}
body .ui-toolbar {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
}
body .ui-toolbar button {
  vertical-align: middle;
}
body .ui-toolbar .ui-toolbar-separator {
  vertical-align: middle;
  color: #24292F;
  margin: 0 0.5em;
}
body .ui-card {
  background-color: #FAFAFA;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .ui-paginator {
  background-color: #FFFFFF;
  border: 1px solid #A7A7A7;
  padding: 0;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #555555;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-paginator .ui-paginator-current {
  color: #555555;
  height: 2.286em;
  min-width: 2.286em;
  line-height: 2.286em;
}
body .ui-paginator .ui-dropdown {
  border: 0 none;
}
body .ui-paginator .ui-dropdown .ui-dropdown-trigger, body .ui-paginator .ui-dropdown .ui-dropdown-label {
  color: #555555;
}
body .ui-paginator .ui-dropdown:hover .ui-dropdown-trigger, body .ui-paginator .ui-dropdown:hover .ui-dropdown-label {
  color: #333333;
}
body .ui-paginator .ui-paginator-first:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-prev:before {
  position: relative;
}
body .ui-paginator .ui-paginator-next:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-last:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #555555;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-paginator .ui-dropdown {
  margin-left: 0.5em;
  height: 2.286em;
  min-width: auto;
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #A7A7A7;
  font-weight: 700;
  color: #333333;
  background-color: #FAFAFA;
}
body .ui-table .ui-table-tbody > tr > td {
  padding: none;
}
body .ui-table .ui-table-tfoot > tr > td {
  padding: none;
  border: 1px solid #A7A7A7;
  font-weight: 700;
  color: #333333;
  background-color: #FFFFFF;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #555555;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #F7F7F7;
  color: #333333;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-table .ui-editable-column input {
  font-size: 14px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}
body .ui-table .ui-editable-column input:focus {
  outline: 1px solid #2096CD;
  outline-offset: 2px;
}
body .ui-table .ui-table-tbody > tr {
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #A7A7A7;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 #0276B3;
  -moz-box-shadow: inset 0 -2px 0 0 #0276B3;
  box-shadow: inset 0 -2px 0 0 #0276B3;
}
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #F7F7F7;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #A7A7A7;
  color: #24292F;
}
body .ui-table .ui-column-resizer-helper {
  background-color: #2096CD;
}
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #A7A7A7;
  }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #A7A7A7;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none;
  }
}
body .ui-datagrid .ui-datagrid-header {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-datagrid .ui-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datagrid .ui-datagrid-footer {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datalist .ui-datalist-header {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-datalist .ui-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datalist .ui-datalist-footer {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-datascroller .ui-datascroller-header {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-datascroller .ui-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-datascroller .ui-datascroller-footer {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-header {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-content {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-virtualscroller .ui-virtualscroller-content .ui-virtualscroller-list li {
  border-bottom: 1px solid #A7A7A7;
}
body .ui-virtualscroller .ui-virtualscroller-footer {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-dataview .ui-dataview-header {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-dataview .ui-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-dataview .ui-dataview-footer {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .fc th {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  color: #24292F;
  padding: 0.571em 1em;
}
body .fc td.ui-widget-content {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  color: #333333;
}
body .fc td.fc-head-container {
  border: 1px solid #A7A7A7;
}
body .fc .fc-row {
  border-right: 1px solid #A7A7A7;
}
body .fc .fc-event {
  background-color: #0276B3;
  border: 1px solid #0276B3;
  color: #FFFFFF;
}
body .fc .fc-toolbar .fc-button {
  color: #FFFFFF;
  background-color: #0276B3;
  border: 1px solid #092A46;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: #092A46;
  color: #FFFFFF;
  border-color: #0C1218;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .fc .fc-divider {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
}
body .ui-fluid .fc .fc-toolbar .fc-button {
  width: auto;
}
body .ui-picklist .ui-picklist-buttons button {
  font-size: 16px;
}
body .ui-picklist .ui-picklist-caption {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-picklist .ui-picklist-filter-container input.ui-picklist-filter {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2096CD;
}
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em;
}
body .ui-picklist .ui-picklist-list {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-picklist .ui-picklist-list .ui-picklist-droppoint-highlight {
  background-color: #0276B3;
}
@media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em;
  }
}
body .ui-orderlist .ui-orderlist-controls {
  padding: 0.571em 1em;
}
body .ui-orderlist .ui-orderlist-controls button {
  font-size: 16px;
}
body .ui-orderlist .ui-orderlist-caption {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-orderlist .ui-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #A7A7A7;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-orderlist .ui-orderlist-filter-container input.ui-inputtext {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-orderlist .ui-orderlist-filter-container .ui-orderlist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2096CD;
}
body .ui-orderlist .ui-orderlist-list {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-droppoint-highlight {
  background-color: #0276B3;
}
body .ui-tree {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree .ui-tree-container {
  padding: 0.286em;
  margin: 0;
}
body .ui-tree .ui-tree-container .ui-treenode {
  padding: 0.143em 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  padding: 0;
  border: 1px solid transparent;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #555555;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #555555;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
  margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
  line-height: inherit;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
  background-color: #0276B3;
}
body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
  color: #2096CD;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.8em;
  color: #2096CD;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
  color: #012335;
}
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #FAFAFA;
}
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #A7A7A7;
  border-color: #FAFAFA;
}
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #A7A7A7;
  border-color: #FAFAFA;
}
body .ui-organizationchart .ui-organizationchart-node-content {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #555555;
}
body .ui-carousel {
  padding: 0;
}
body .ui-carousel .ui-carousel-header {
  background-color: #FAFAFA;
  color: #24292F;
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  margin: 0;
  font-weight: 400;
}
body .ui-carousel .ui-carousel-header .ui-carousel-header-title {
  padding: 0;
}
body .ui-carousel .ui-carousel-viewport {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
  padding: 0;
}
body .ui-carousel .ui-carousel-viewport .ui-carousel-items .ui-carousel-item {
  border: 1px solid #A7A7A7;
}
body .ui-carousel .ui-carousel-footer {
  background-color: #FAFAFA;
  color: #24292F;
  padding: 0.571em 1em;
  border: 1px solid #A7A7A7;
  margin: 0;
}
body .ui-carousel .ui-carousel-button {
  color: #24292F;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-carousel .ui-carousel-button.ui-carousel-next-button:before {
  content: "";
}
body .ui-carousel .ui-carousel-button.ui-carousel-prev-button:before {
  content: "";
}
body .ui-carousel .ui-carousel-button:not(.ui-state-disabled):hover {
  color: #333333;
}
body .ui-carousel .ui-carousel-page-links {
  margin: 0.125em 0.5em;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link {
  color: #24292F;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-carousel .ui-carousel-page-links .ui-carousel-page-link:hover {
  color: #333333;
}
body .ui-treetable .ui-treetable-caption,
body .ui-treetable .ui-treetable-summary {
  background-color: #FAFAFA;
  color: #24292F;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-treetable .ui-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #A7A7A7;
  font-weight: 700;
  color: #333333;
  background-color: #FAFAFA;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  padding: none;
}
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: none;
  border: 1px solid #A7A7A7;
  font-weight: 700;
  color: #333333;
  background-color: #FFFFFF;
}
body .ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #555555;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #F7F7F7;
  color: #333333;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #333333;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-treetable .ui-editable-column input {
  font-size: 14px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}
body .ui-treetable .ui-editable-column input:focus {
  outline: 1px solid #2096CD;
  outline-offset: 2px;
}
body .ui-treetable .ui-treetable-tbody > tr {
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #A7A7A7;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-toggler {
  color: #555555;
  vertical-align: middle;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td {
  background-color: inherit;
  border: 1px solid #A7A7A7;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td .ui-treetable-toggler {
  color: #FFFFFF;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
  background-color: #0276B3;
  color: #FFFFFF;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td {
  background-color: inherit;
  border: 1px solid #A7A7A7;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td .ui-treetable-toggler {
  color: #FFFFFF;
}
body .ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #A7A7A7;
  color: #24292F;
}
body .ui-treetable .ui-column-resizer-helper {
  background-color: #2096CD;
}

body .ui-messages {
  padding: 1em;
  margin: 1em 0;
}
body .ui-messages.ui-messages-info {
  background-color: #a9daf5;
  border: 0 none;
  color: #0C1218;
}
body .ui-messages.ui-messages-info .ui-messages-icon {
  color: #0C1218;
}
body .ui-messages.ui-messages-info .ui-messages-close {
  color: #0C1218;
}
body .ui-messages.ui-messages-success {
  background-color: #cafac6;
  border: 0 none;
  color: #0C1218;
}
body .ui-messages.ui-messages-success .ui-messages-icon {
  color: #0C1218;
}
body .ui-messages.ui-messages-success .ui-messages-close {
  color: #0C1218;
}
body .ui-messages.ui-messages-warn {
  background-color: #ffd9b8;
  border: 0 none;
  color: #0C1218;
}
body .ui-messages.ui-messages-warn .ui-messages-icon {
  color: #0C1218;
}
body .ui-messages.ui-messages-warn .ui-messages-close {
  color: #0C1218;
}
body .ui-messages.ui-messages-error {
  background-color: #fbdcd7;
  border: 0 none;
  color: #0C1218;
}
body .ui-messages.ui-messages-error .ui-messages-icon {
  color: #0C1218;
}
body .ui-messages.ui-messages-error .ui-messages-close {
  color: #0C1218;
}
body .ui-messages .ui-messages-close {
  top: 0.25em;
  right: 0.5em;
  font-size: 1.5em;
}
body .ui-messages .ui-messages-icon {
  font-size: 2em;
}
body .ui-message {
  padding: 0.429em;
  margin: 0;
}
body .ui-message.ui-message-info {
  background-color: #a9daf5;
  border: 0 none;
  color: #0C1218;
}
body .ui-message.ui-message-info .ui-message-icon {
  color: #0C1218;
}
body .ui-message.ui-message-success {
  background-color: #cafac6;
  border: 0 none;
  color: #0C1218;
}
body .ui-message.ui-message-success .ui-message-icon {
  color: #0C1218;
}
body .ui-message.ui-message-warn {
  background-color: #ffd9b8;
  border: 0 none;
  color: #0C1218;
}
body .ui-message.ui-message-warn .ui-message-icon {
  color: #0C1218;
}
body .ui-message.ui-message-error {
  background-color: #fbdcd7;
  border: 0 none;
  color: #0C1218;
}
body .ui-message.ui-message-error .ui-message-icon {
  color: #0C1218;
}
body .ui-message .ui-message-icon {
  font-size: 1.25em;
}
body .ui-message .ui-message-text {
  font-size: 1em;
}
body .ui-growl {
  top: 70px;
}
body .ui-growl .ui-growl-item-container {
  margin: 0 0 1em 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-growl .ui-growl-item-container .ui-growl-item {
  padding: 1em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-message {
  margin: 0 0 0 4em;
}
body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-image {
  font-size: 2.571em;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info {
  background-color: #a9daf5;
  border: 0 none;
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-info .ui-growl-image {
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success {
  background-color: #cafac6;
  border: 0 none;
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-success .ui-growl-image {
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn {
  background-color: #ffd9b8;
  border: 0 none;
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-warn .ui-growl-image {
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error {
  background-color: #fbdcd7;
  border: 0 none;
  color: #0C1218;
}
body .ui-growl .ui-growl-item-container.ui-growl-message-error .ui-growl-image {
  color: #0C1218;
}
body .ui-toast .ui-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0;
}
body .ui-toast .ui-toast-message.ui-toast-message-info {
  background-color: #a9daf5;
  border: 0 none;
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-success {
  background-color: #cafac6;
  border: 0 none;
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn {
  background-color: #ffd9b8;
  border: 0 none;
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-close-icon {
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-error {
  background-color: #fbdcd7;
  border: 0 none;
  color: #0C1218;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
  color: #0C1218;
}

body .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
body .ui-overlaypanel {
  background-color: #FAFAFA;
  color: #333333;
  padding: 0;
  border: 1px solid #FAFAFA;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-overlaypanel .ui-overlaypanel-content {
  padding: 0.571em 1em;
}
body .ui-overlaypanel .ui-overlaypanel-close {
  background-color: #2096CD;
  color: #FFFFFF;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-overlaypanel .ui-overlaypanel-close:hover {
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-overlaypanel .ui-overlaypanel-close .ui-overlaypanel-close-icon {
  line-height: inherit;
}
body .ui-overlaypanel:after {
  border-color: rgba(250, 250, 250, 0);
  border-bottom-color: #FAFAFA;
}
body .ui-overlaypanel:before {
  border-color: rgba(250, 250, 250, 0);
  border-bottom-color: #FAFAFA;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:after {
  border-top-color: #FAFAFA;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:before {
  border-top-color: #FAFAFA;
}
body .ui-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #24292F;
  padding: 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  margin: 0;
  float: none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #24292F;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #333333;
}
body .ui-dialog .ui-dialog-content {
  background-color: #FAFAFA;
  color: #333333;
  border: 1px solid #FAFAFA;
  padding: 0.571em 1em;
}
body .ui-dialog .ui-dialog-footer {
  border: 1px solid #A7A7A7;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  top: -1px;
}
body .ui-dialog .ui-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
  padding: 1.5em;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span.ui-icon {
  margin-right: 0.35em;
  font-size: 16px;
}
body .ui-sidebar {
  background-color: #FAFAFA;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-sidebar .ui-sidebar-close {
  color: #555555;
}
body .ui-sidebar .ui-sidebar-close:hover {
  color: #333333;
}
body .ui-tooltip .ui-tooltip-text {
  background-color: #0C1218;
  color: #FFFFFF;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #0C1218;
}
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #0C1218;
}
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #0C1218;
}
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #0C1218;
}
body .ui-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-lightbox .ui-lightbox-caption {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #24292F;
  padding: 0.571em 1em;
  font-weight: 400;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  color: #24292F;
  margin: 0;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  padding: 0;
  color: #24292F;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  color: #333333;
}
body .ui-lightbox .ui-lightbox-content-wrapper {
  overflow: hidden;
  background-color: #FAFAFA;
  color: #333333;
  border: 0 none;
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 3em;
  color: #FFFFFF;
  margin-top: -0.5em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading ~ a {
  display: none;
}

body .ui-breadcrumb {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #333333;
  margin: 0;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #555555;
}
body .ui-breadcrumb ul li:first-child a {
  color: #555555;
  margin: 0;
}
body .ui-breadcrumb ul li .ui-menuitem-icon {
  color: #555555;
}
body .ui-steps {
  position: relative;
}
body .ui-steps .ui-steps-item {
  background-color: transparent;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #555555;
  position: relative;
  top: 16px;
  margin-bottom: 14px;
  width: 28px;
  height: 28px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: block;
  margin-top: 6px;
  color: #333333;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background: #0276B3;
  color: #FFFFFF;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #333333;
}
body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
  display: block;
}
body .ui-steps:before {
  content: " ";
  border-top: 1px solid #A7A7A7;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}
body .ui-menu .ui-menuitem-link,
body .ui-menubar .ui-menuitem-link,
body .ui-tieredmenu .ui-menuitem-link,
body .ui-contextmenu .ui-menuitem-link,
body .ui-megamenu .ui-menuitem-link,
body .ui-slidemenu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-menu .ui-menuitem-link:hover,
body .ui-menubar .ui-menuitem-link:hover,
body .ui-tieredmenu .ui-menuitem-link:hover,
body .ui-contextmenu .ui-menuitem-link:hover,
body .ui-megamenu .ui-menuitem-link:hover,
body .ui-slidemenu .ui-menuitem-link:hover {
  background-color: #0C1218;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-text,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-icon,
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-menu {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
}
body .ui-menu .ui-menuitem {
  margin: 0;
}
body .ui-menu.ui-shadow {
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-menu .ui-submenu-header {
  margin: 0;
  padding: 1em 1.25em;
  color: #24292F;
  background-color: #FAFAFA;
  font-weight: 400;
  border: 0 none;
}
body .ui-menu .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 1px 0 0 0;
}
body .ui-menubar {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
}
body .ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
  padding: 1em 1.25em;
}
body .ui-menubar .ui-menubar-root-list > .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 0 0 0 1px;
}
body .ui-menubar .ui-submenu-list {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-menubar .ui-submenu-list .ui-menuitem {
  margin: 0;
}
body .ui-menubar .ui-submenu-list .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 1px 0 0 0;
}
body .ui-menubar .ui-menuitem {
  margin: 0;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #0C1218;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-contextmenu {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-contextmenu .ui-submenu-list {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-contextmenu .ui-menuitem {
  margin: 0;
}
body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #0C1218;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 1px 0 0 0;
}
body .ui-tieredmenu {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
}
body .ui-tieredmenu .ui-submenu-list {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-tieredmenu .ui-menuitem {
  margin: 0;
}
body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #0C1218;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 1px 0 0 0;
}
body .ui-slidemenu {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
}
body .ui-slidemenu .ui-submenu-list {
  padding: 0;
  background-color: #092A46;
  border: 0 none;
}
body .ui-slidemenu .ui-menuitem {
  margin: 0;
}
body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #0C1218;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-slidemenu-backward {
  margin: 0;
  padding: 0.571em 1em;
  color: #24292F;
  background-color: #FAFAFA;
  font-weight: 400;
  border: 0 none;
}
body .ui-slidemenu .ui-slidemenu-backward:hover {
  background-color: #FAFAFA;
  color: #24292F;
}
body .ui-slidemenu .ui-menu-separator {
  border: 1px solid #0C1218;
  border-width: 1px 0 0 0;
}
body .ui-tabmenu {
  border: 0 none;
}
body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #A7A7A7;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  position: static;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  margin-right: 2px;
  margin-bottom: -1px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: #24292F;
  padding: 0.571em 1em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-text {
  color: #24292F;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-icon {
  color: #24292F;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #FAFAFA;
  border: 1px solid #A7A7A7;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
  background-color: #092A46;
  border: 1px solid #555555;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-megamenu {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
}
body .ui-megamenu .ui-megamenu-submenu-header {
  margin: 0;
  padding: 0.571em 1em;
  color: #24292F;
  background-color: #FAFAFA;
  font-weight: 400;
  border: 0 none;
}
body .ui-megamenu .ui-megamenu-panel {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #FAFAFA;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-megamenu .ui-menuitem {
  margin: 0;
}
body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #0C1218;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-megamenu.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
  right: 0.429em;
}
body .ui-panelmenu .ui-icon {
  position: static;
}
body .ui-panelmenu .ui-panelmenu-header {
  padding: 0;
}
body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #24292F;
  padding: 1em 1.25em;
  font-weight: 400;
  position: static;
  font-size: 14px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #24292F;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  outline: 0 none;
  border: 1px solid #A7A7A7;
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
  color: #333333;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #555555;
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
  outline: 0 none;
  border: 1px solid #555555;
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 2px;
}
body .ui-panelmenu .ui-panelmenu-panel:first-child {
  margin-top: 0;
}
body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
  background-color: #092A46;
  border: 1px solid #0C1218;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  margin: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
  background-color: #0C1218;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-icon, body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}

body .ui-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #A7A7A7;
}
body .ui-progressbar .ui-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #0276B3;
}
body .ui-progressbar .ui-progressbar-label {
  margin-top: 0;
  color: #333333;
  line-height: 24px;
}
body .ui-galleria .ui-galleria-nav-prev,
body .ui-galleria .ui-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #555555;
}
body .ui-galleria .ui-galleria-nav-prev:hover,
body .ui-galleria .ui-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 16px;
}
body .ui-galleria .ui-galleria-nav-next {
  font-size: 16px;
}
body .ui-terminal {
  background-color: #FAFAFA;
  color: #333333;
  border: 1px solid #A7A7A7;
  padding: 0.571em 1em;
}
body .ui-terminal .ui-terminal-input {
  font-size: 14px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  height: 16px;
}
body .ui-terminal .ui-terminal-command {
  height: 16px;
}
body .ui-inplace {
  min-height: 2.357em;
}
body .ui-inplace .ui-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-inplace .ui-inplace-display:not(.ui-state-disabled):hover {
  background-color: #FAFAFA;
  color: #333333;
}
body .ui-fluid .ui-inplace.ui-inplace-closable .ui-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em);
}

/* Add your customizations of theme here */
/*Layout*/
.export-btn-group {
  float: right;
}

body .ui-button {
  margin: 0.125em;
  border: none;
}

/*Theme*/
.ui-button-link {
  background: none !important;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0276B3;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

i.button-icon {
  padding-right: 0.3em;
  margin-left: -0.5em;
}

/*Layout*/
/* primeng doesn't have layout helpers. add them here */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*Theme*/
.nowrap {
  white-space: nowrap;
}

/*Layout*/
/* primeng tables are fixed cell widths. gross. override back to default */
.ui-datatable table {
  table-layout: auto;
}

.ui-table table {
  table-layout: auto;
}

body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td {
  padding: 0.571em 0.35em;
}

/*Layout*/
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.form-actions {
  padding: 1em;
  margin: 0 -0.3125em 0.3125em -0.3125em;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  width: 100%;
}

input[type=text], input[type=number] {
  border: 1px solid #A7A7A7;
  border-radius: 3px;
  padding: 5px;
  height: 34px;
}

.ui-autocomplete-token {
  margin: 0.125em;
}

input[type=text], input[type=number] {
  height: 34px;
}

form .ui-dropdown {
  width: 100% !important;
  height: 34px;
  padding: 0.125em;
}

.ui-calendar input {
  width: 80%;
}

/* primeng doesn't provide much form styling. add back in */
.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext {
  width: 100%;
}

form {
  border-radius: 5px;
  padding: 0.5em;
  box-shadow: 0 1px 10px #ccc;
}

body .ui-inputtext {
  font-size: 15px;
}

.ui-autocomplete {
  width: 100% !important;
}

body .ui-chkbox {
  margin: 4px;
}

/*Theme*/
.form-actions {
  border-top: 1px solid #e5e5e5;
}

label, legend {
  font-weight: 700;
  font-size: 1rem;
}

fieldset label {
  font-weight: 400;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  padding: 0.3125em;
  font-size: 1em;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
}

/* override PrimeNG theme font family */
/*Font paths with obfuscated names from WebType*/
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*ui component Colors coming from the WCAG 2.0 AA Accessibility Compliance guide from GTB*/
/* Backgrounds */
/* Blue */
/* Gray */
/* Text and Icons */
/* CTA Colors */
/* Text Dark */
/* Alerts */
/*Responsive*/
/*Layout*/
#app-header #app-branding h1 {
  margin: 0;
  line-height: 1.5em;
  display: inline-block;
}

#app-header #app-branding {
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5em 0;
}

#app-header #app-branding #app-name {
  flex-grow: 1;
  padding: 0 1em;
  align-self: center;
}

#app-header #app-branding #app-login {
  float: right;
  text-align: right;
  align-self: center;
}

#app-header #app-branding #app-icon img {
  display: inline-block;
}

#app-header #app-branding #app-login a {
  margin: 0 0.5em;
}

#app-header #app-branding #app-login a .fa {
  padding-right: 0.25em;
}

#app-footer > div {
  width: 100%;
}

#app-branding #app-login {
  display: block;
}

.sidebar-app-login, .btn-sidebar {
  display: none;
}

#app-header {
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 0.5em 1em;
}

html {
  box-sizing: border-box;
}

/*Theme*/
#app-header #app-branding h2 {
  font-weight: 200;
}

#app-footer {
  background-image: linear-gradient(to bottom, #FAFAFA 0, #FFFFFF 100%);
}

body {
  margin: 0;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

/*Layout*/
dl, h5 {
  margin: 0;
  padding: 0;
}

dd {
  margin-left: 0;
  margin-top: 0.5rem;
  word-wrap: break-word;
}

.ui-panel-titlebar a {
  color: #0276B3;
}

body .ui-dataview .ui-dataview-header {
  margin-top: 0.5em;
}
body .ui-dataview .ui-dataview-content .ui-panel-titlebar {
  margin: 0.5em 0.5em 0 0.5em !important;
}
body .ui-dataview .ui-dataview-content .ui-panel-content {
  margin: 0 0.5em 0 0.5em !important;
}
body .ui-dataview .ui-dataview-content .ui-panel-footer {
  margin: 0 0.5em 0.5em 0.5em !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

/*Theme*/
dt {
  font-weight: bold;
}

#app-header #app-branding #app-icon > img {
  max-width: 75px;
  transition: max-width 0.25s ease-in;
}

@media (min-width: 480px) {
  #app-header #app-branding #app-icon > img {
    max-width: 100px;
    transition: max-width 0.25s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding #app-icon > img {
    max-width: 105px;
    transition: max-width 0.25s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding #app-icon > img {
    max-width: 110px;
    transition: max-width 0.25s ease-in;
  }
}
@media (min-width: 1024px) {
  #app-header #app-branding #app-icon > img {
    max-width: 115px;
    transition: max-width 0.25s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding #app-icon > img {
    max-width: 120px;
    transition: max-width 0.25s ease-in;
  }
}
/*Theme*/
@media (min-width: 50px) and (max-width: 479px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.125rem;
    transition: font-size 0.25s ease-in;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.25rem;
    /*20px; this is what it used to be before converting to rems*/
    transition: font-size 0.25s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.375rem;
    transition: font-size 0.25s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.5rem;
    transition: font-size 0.25s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.625rem;
    transition: font-size 0.25s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.75rem;
    transition: font-size 0.25s ease-in;
  }
}
/*Layout*/
.btn-sidebar button {
  padding: 0.25em 0.5em;
}

.btn-sidebar {
  float: right;
  margin-top: 0.25em;
  transition: margin-top 0.25s ease-in;
}

@media (min-width: 768px) {
  #app-sidebar {
    display: none;
  }

  .btn-sidebar {
    display: none;
  }
}
@media (min-width: 480px) {
  .btn-sidebar {
    margin-top: 0.5em;
    transition: margin-top 0.25s ease-in;
  }
}
/*Theme*/
.btn-sidebar button {
  background: none;
  color: #092A46;
}

table .ui-widget-content td:first-child {
  overflow: visible;
}

/*Layout*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 0.5em;
}

h1, .h1, h2, .h2 {
  margin-bottom: 0.41666em;
}

p {
  margin: 0 0 0.625em;
}

hr {
  margin-bottom: 1em;
}

#app-content {
  flex-grow: 2;
  margin: 0 1.5em 1em;
}

/*Theme*/
h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  font-family: FordAntennaCond, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
}

h3, .h3 {
  color: #777;
  font-size: 1.18rem;
}

h4, .h4 {
  color: #0C1218;
  font-size: 1rem;
}

h5, .h5 {
  color: #555555;
}

h5, h6, .h5, .h6 {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
}

a:hover {
  color: #0C1218;
}

hr {
  border: 0;
  border-top: 1px solid #FAFAFA;
}

#app-branding h1 {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
}

label[class^=ui-g] {
  padding-bottom: 0;
}

textarea {
  width: 99%;
}

.ui-calendar .ui-calendar-button {
  margin: 0 0.125px 0 0;
}

.ui-autocomplete-input-token, .ui-autocomplete-input-token input {
  width: 100%;
  border: none;
}

.ui-dropdown {
  width: auto !important;
}

/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */
@font-face {
  /* Ford Antenna Regular */
  font-family: "FordAntenna";
  src: url("webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Bold */
  font-family: "FordAntenna";
  src: url("webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-1.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  /* Ford Antenna Regular Italic */
  font-family: "FordAntenna";
  src: url("webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-1.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Bold Italic */
  font-family: "FordAntenna";
  src: url("webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-1.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
}
@font-face {
  /* Ford Antenna Medium */
  font-family: "FordAntennaMedium";
  src: url("webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Light */
  font-family: "FordAntennaLight";
  src: url("webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Light Italic */
  font-family: "FordAntennaLight";
  src: url("webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Condensed Regular */
  font-family: "FordAntennaCond";
  src: url("webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Condensed Bold */
  font-family: "FordAntennaCond";
  src: url("webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  /* Ford Antenna Condensed Regular Italic */
  font-family: "FordAntennaCond";
  src: url("webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-1.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Condensed Bold Italic */
  font-family: "FordAntennaCond";
  src: url("webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-1.ttf") format("truetype");
  font-style: italic;
  font-weight: bold;
}
@font-face {
  /* Ford Antenna Condensed Medium */
  font-family: "FordAntennaCondMed";
  src: url("webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  /* Ford Antenna Condensed Medium Italic*/
  font-family: "FordAntennaCondMed";
  src: url("webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-5.woff2") format("woff2"), url("webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-3.woff") format("woff"), url("webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-1.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}
/********************************* [END] Ford Fonts *********************************/
/* Prime Designer version 7.0.1 */
/* Add your customizations of theme here */
/* Prime Designer version 7.0.1 */
#sra-nav .ui-menubar {
  padding: 0;
  background: linear-gradient(to bottom, #5883A5 0%, #0F4C79 100%);
  -webkit-box-shadow: 0 5px 15px 4px #bebebe;
  -moz-box-shadow: 0 5px 15px 4px #bebebe;
  box-shadow: 0 5px 15px 4px #bebebe;
}

#sra-nav .ui-menuitem .ui-menuitem-link {
  padding: 1em;
  border-radius: 0;
  border-right: 1px solid #bebebe;
}

#sra-nav .ui-menuitem-text {
  color: #fff;
  font-weight: bold;
}

#sra-nav .ui-menubar.ui-corner-all {
  border-radius: 5px;
}

#sra-nav .ui-state-active {
  background: linear-gradient(to bottom, #596B83 0%, #163353 100%);
}

#sra-nav .ui-menuitem-link:hover {
  background-color: #163353;
}

/* Prime Designer version 7.0.1 */
body .ui-sidebar {
  padding: 0;
}

body .ui-sidebar .ui-sidebar-close {
  color: #092A46;
  font-size: 1.7em;
}

/* Prime Designer version 7.0.1 */
body .ui-dataview .ui-dataview-content {
  padding: 0;
}

body .ui-table .ui-menu {
  background-color: #FFFFFF;
}
body .ui-table .ui-menu .ui-menuitem-link .ui-menuitem-text {
  color: #333333;
}
body .ui-table .ui-menu .ui-menuitem-link.ui-state-active .ui-menuitem-text,
body .ui-table .ui-menu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-table .ui-menu .ui-menuitem-link:hover {
  background-color: #092A46;
}

@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 1px solid #A7A7A7;
  }
}
/* Prime Designer version 7.0.1 */
button.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only:enabled:hover {
  background-color: inherit;
  color: inherit;
}

.ui-button-icon-only .ui-button-icon-left {
  font-size: 1.25em;
}

.btn-sidebar button:enabled:focus {
  box-shadow: none;
}

body p-sidebar p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-header > a,
body p-sidebar p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-header .ui-state-active > a,
body p-sidebar p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-header :not(.ui-state-active) > a,
body p-sidebar p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-header :not(.ui-state-active) > a:hover {
  border-top: 1px solid #A7A7A7;
  border-bottom: 1px solid #A7A7A7;
  border-left: none !important;
  border-right: none !important;
}
body p-sidebar > .ui-sidebar.ui-widget {
  padding: 0;
  overflow-y: auto;
}
body .ui-sidebar p.user-info {
  float: right;
  margin-right: 1em;
  margin-top: 1em;
}
body .ui-sidebar-close {
  z-index: 10000;
  position: absolute;
  right: 0;
  padding: 0.125em 0.25em;
}
body p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-header {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
}
body p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-content {
  background-color: #FFFFFF;
  border: none;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
}
body p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-content .ui-menuitem {
  border-radius: 0;
}
body p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  color: #24292F;
  border-radius: 0;
}
body p-panelMenu > .ui-panelmenu.ui-widget .ui-panelmenu-content .ui-menuitem .ui-menuitem-link.ui-state-active {
  background-color: #092A46;
  color: #FFFFFF;
}
body .ui-sidebar {
  border: none;
}
body .ui-sidebar-close {
  color: #092A46;
  margin-top: 0.2em;
}