.btn-sidebar button:enabled:focus {
	box-shadow: none;
}

// sidebar properties
body {
	p-sidebar {
		p-panelMenu > .ui-panelmenu.ui-widget {
			.ui-panelmenu-header {
				> a,
				.ui-state-active > a,
				:not(.ui-state-active) > a,
				:not(.ui-state-active) > a:hover {
					border-top: 1px solid $light-grey;
					border-bottom: 1px solid $light-grey;
					border-left: none !important;
					border-right: none !important;
				}
			}
		}
		> .ui-sidebar.ui-widget {
			padding: 0;
			overflow-y: auto;
		}
	}
	.ui-sidebar p.user-info {
		float: right;
		margin-right: 1em;
		margin-top: 1em;
	}
	// side bar close icon layout
	.ui-sidebar-close {
		z-index: 10000;
		position: absolute;
		right: 0;
		padding: .125em .25em;
	}

	p-panelMenu > .ui-panelmenu.ui-widget {
		.ui-panelmenu-header {
			font-family: $fontFamilyFordAntenna;
		}

		.ui-panelmenu-content {
			background-color: $white;
			border: none;
			font-family: $fontFamilyFordAntennaLight;

			.ui-menuitem {
				border-radius: 0;
			}

			.ui-menuitem .ui-menuitem-link {
				color: $ninety-percent-navy;
				border-radius: 0;
			}

			.ui-menuitem .ui-menuitem-link.ui-state-active {
				background-color: $ford-blue;
				color: $white;
			}
		}
	}

	.ui-sidebar {
		border: none;
	}

	// sidebar close theme
	.ui-sidebar-close {
		color: $ford-blue;
		margin-top: 0.2em;
	}
}
