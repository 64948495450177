@import 'variables';

/*Layout*/
// Header text margin
#app-header #app-branding h1 {
	margin: 0;
	line-height: 1.5em;
	display: inline-block;
}
// Header flex properties
#app-header #app-branding{
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.5em 0;
}
// Header flex properties
#app-header #app-branding #app-name{
	flex-grow: 1;
	padding: 0 1em;
	align-self: center;
}
// Header login alignment
#app-header #app-branding #app-login{
	float: right;
	text-align: right;
	align-self: center;
}
// Need to check with team
#app-header #app-branding #app-icon img{
	display: inline-block;
}
// Margin for login
#app-header #app-branding #app-login a{
	margin: 0 .5em;
}
// padding for welcome
#app-header #app-branding #app-login a .fa{
	padding-right: .25em;
}
// need to check with team
#app-footer > div {
	width: 100%;
}
// check with team
#app-branding #app-login{
	display:block;
}
// for hiding hamburger menu and login with in the header
.sidebar-app-login, .btn-sidebar{
	display: none;
}
// Header margin
#app-header{
	flex-shrink: 0;
	flex-basis: auto;
	margin: 0 0.5em 1em;
}
// check with team
html {
	box-sizing: border-box;
}

/*Theme*/
// font-weight text (Ford JAB Agent App )
#app-header #app-branding h2 {
	font-weight: 200;
	//font-size: 30px;
}

// Footer BG image
#app-footer {
	background-image: linear-gradient(to bottom, $light-grey-background-two 0, $white 100%);
}
// header and footer fonts
body{
	margin: 0;
	font-family: $fontFamily;
	
}
