/* Prime Designer version 7.0.1 */
@import '../../variables';
// designer overrides

// Menu related properties


#sra-nav .ui-menubar {
	padding: 0;
	background: linear-gradient(to bottom, #5883A5 0%, #0F4C79 100%);
	-webkit-box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
	-moz-box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
	box-shadow: 0 5px 15px 4px rgb(190, 190, 190);
}

#sra-nav .ui-menuitem .ui-menuitem-link {
	padding: 1em;
	border-radius: 0;
	border-right: 1px solid rgb(190, 190, 190);
}

#sra-nav .ui-menuitem-text{
	color: #fff;
	font-weight: bold;
}

#sra-nav .ui-menubar.ui-corner-all {
	border-radius: 5px;
}

#sra-nav .ui-state-active {
	background: linear-gradient(to bottom, #596B83 0%, #163353 100%);
}

#sra-nav .ui-menuitem-link:hover {
		background-color: #163353;
	}
