//main color scheme
$primaryColor: $CTA-icon-cyan;
$primaryDarkColor: $CTA-dark-cyan;
$primaryDarkerColor: $ford-blue;
$primaryTextColor: $white;

//Global
$fontSize:14px;
$textColor: $charcoal;
$textSecondaryColor: $grey;
$borderRadius:3px;
$transitionDuration:.2s;
$primeIconFontSize:1.25em;

//anchors
$linkColor:$primaryDarkerColor;
$linkHoverColor: $navy;
$linkActiveColor:$primaryDarkColor;

//highlight
$highlightBgColor:$primaryDarkColor;
$highlightColorText:$primaryTextColor;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:0.429em;
$inputBgColor: $white;
$inputBorder:1px solid $light-grey;
$inputHoverBorderColor: $navy;
$inputFocusBorderColor:$primaryColor;
$inputFocusShadow:none;
$inputErrorBorder:1px solid $dark-alert-red;
$inputPlaceholderTextColor: $grey;
$inputTransition:border-color $transitionDuration;

//input groups
$inputGroupBorderColor: $light-grey;
$inputGroupBgColor: $light-grey-background-two;
$inputGroupTextColor:$textSecondaryColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth:12em;
$inputListBgColor: $white;
$inputListPadding:0;
$inputListItemPadding:0.429em 0.857em;
$inputListItemBgColor:transparent;
$inputListItemTextColor:$textColor;
$inputListItemHoverBgColor: $light-grey-background-two;
$inputListItemHoverTextColor:$textColor;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightColorText;
$inputListItemBorder:0 none;
$inputListItemMargin:0;
$inputListItemDividerColor: $light-grey;
$inputListHeaderPaddingTop:0.429em;
$inputListHeaderPaddingLeft:0.857em;
$inputListHeaderPaddingRight:0.857em;
$inputListHeaderPaddingBottom:0.429em;
$inputListHeaderMargin:0;
$inputListHeaderBgColor: $white;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid $light-grey;
$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textSecondaryColor;
$inputListHeaderCloseIconHoverColor:$textColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding:12px;
$inputContentPanelBgColor: $white;
$inputContentPanelTextColor:$textColor;

//static positioned input lists like listbox
$inputListBorder:1px solid $light-grey;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder:1px solid $light-grey-background-two;
$inputOverlayShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor:$textSecondaryColor;

//button
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding:0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.357em;
$buttonBgColor:$primaryDarkColor;
$buttonBorder:1px solid $primaryDarkerColor;
$buttonTextColor:$primaryTextColor;
$buttonHoverBgColor:$primaryDarkerColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverBorderColor: $navy;
$buttonActiveBgColor: $navy;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkerColor;
$buttonFocusBorderColor: $navy;
$buttonFocusOutline:0 none;
$buttonFocusOutlineOffset:0px;
$buttonFocusShadow:0 0 0 0.2em lighten($primaryColor, 35%);
$buttonTransition:background-color $transitionDuration,box-shadow $transitionDuration;
$raisedButtonShadow:0 2px 3px 0 rgba(0, 0, 0, 0.15);
$roundedButtonBorderRadius:15px;

$secondaryButtonBgColor: $light-grey-background-two;
$secondaryButtonBorder:1px solid $light-grey;
$secondaryButtonTextColor: $charcoal;
$secondaryButtonHoverBgColor: $light-grey;
$secondaryButtonHoverTextColor: $charcoal;
$secondaryButtonHoverBorderColor: $grey;
$secondaryButtonActiveBgColor: $light-grey;
$secondaryButtonActiveTextColor: $charcoal;
$secondaryButtonActiveBorderColor: $grey;
$secondaryButtonFocusShadow:0 0 0 0.2em lighten($primaryColor, 35%);

$infoButtonBgColor:$primaryColor;
$infoButtonBorder:1px solid $primaryDarkColor;
$infoButtonTextColor:$primaryTextColor;
$infoButtonHoverBgColor:$primaryDarkColor;
$infoButtonHoverTextColor:$primaryTextColor;
$infoButtonHoverBorderColor:$primaryColor;
$infoButtonActiveBgColor:$primaryDarkColor;
$infoButtonActiveTextColor:$primaryTextColor;
$infoButtonActiveBorderColor:$primaryColor;
$infoButtonFocusShadow:0 0 0 0.2em lighten($infoButtonBgColor, 35%);

$successButtonBgColor:$dark-alert-green;
$successButtonBorder:1px solid $light-alert-green;
$successButtonTextColor:$primaryTextColor;
$successButtonHoverBgColor:darken($successButtonBgColor, 10%);
$successButtonHoverTextColor:$primaryTextColor;
$successButtonHoverBorderColor:$dark-alert-green;
$successButtonActiveBgColor:darken($successButtonHoverBgColor, 10%);
$successButtonActiveTextColor:$primaryTextColor;
$successButtonActiveBorderColor:$dark-alert-green;
$successButtonFocusShadow:0 0 0 0.2em lighten($successButtonBgColor, 35%);

$warningButtonBgColor:$dark-alert-orange;
$warningButtonBorder:1px solid $light-alert-orange;
$warningButtonTextColor:$primaryTextColor;
$warningButtonHoverBgColor:darken($warningButtonBgColor, 10%);
$warningButtonHoverTextColor:$primaryTextColor;
$warningButtonHoverBorderColor:$dark-alert-orange;
$warningButtonActiveBgColor:darken($warningButtonHoverBgColor, 10%);
$warningButtonActiveTextColor:$primaryTextColor;
$warningButtonActiveBorderColor:$dark-alert-orange;
$warningButtonFocusShadow:0 0 0 0.2em lighten($warningButtonBgColor, 35%);

$dangerButtonBgColor:$dark-alert-red;
$dangerButtonBorder:1px solid $light-alert-red;
$dangerButtonTextColor:$primaryTextColor;
$dangerButtonHoverBgColor:darken($dark-alert-red, 10%);
$dangerButtonHoverTextColor:$primaryTextColor;
$dangerButtonHoverBorderColor:$dark-alert-red;
$dangerButtonActiveBgColor:darken($dangerButtonHoverBgColor, 10%);
$dangerButtonActiveTextColor:$primaryTextColor;
$dangerButtonActiveBorderColor:$dark-alert-red;
$dangerButtonFocusShadow:0 0 0 0.2em lighten($dangerButtonBgColor, 35%);

//checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxActiveBorderColor:$primaryColor;
$checkboxActiveBgColor:$primaryColor;
$checkboxActiveTextColor:$primaryTextColor;
$checkboxActiveHoverBgColor:$primaryDarkerColor;
$checkboxActiveHoverTextColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkerColor;
$checkboxActiveFocusBgColor:$primaryColor;
$checkboxActiveFocusTextColor:$primaryTextColor;
$checkboxActiveFocusBorderColor:$primaryColor;
$checkboxFocusBgColor:$inputBgColor;
$checkboxFocusTextColor:$primaryColor;
$checkboxFocusBorderColor:$primaryColor;
$checkboxFocusShadow:0 0 0 0.2em lighten($primaryColor, 35%);
$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonActiveBorderColor:$primaryColor;
$radiobuttonActiveBgColor:$primaryColor;
$radiobuttonActiveTextColor:$primaryTextColor;
$radiobuttonActiveHoverBgColor:$primaryDarkerColor;
$radiobuttonActiveHoverTextColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkerColor;
$radiobuttonActiveFocusBgColor:$primaryColor;
$radiobuttonActiveFocusTextColor:$primaryTextColor;
$radiobuttonActiveFocusBorderColor:$primaryColor;
$radiobuttonFocusBgColor:$inputBgColor;
$radiobuttonFocusTextColor:$primaryColor;
$radiobuttonFocusBorderColor:$primaryColor;
$radiobuttonFocusShadow:0 0 0 0.2em lighten($primaryColor, 35%);
$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//togglebutton
$toggleButtonBgColor: $white;
$toggleButtonBorder:1px solid $light-grey-background-two;
$toggleButtonTextColor: $charcoal;
$toggleButtonIconColor: $grey;
$toggleButtonHoverBgColor: $light-grey-background-two;
$toggleButtonHoverBorderColor: $light-grey-background-two;
$toggleButtonHoverTextColor: $charcoal;
$toggleButtonHoverIconColor: $navy;
$toggleButtonActiveBgColor:$primaryColor;
$toggleButtonActiveBorderColor:$primaryColor;
$toggleButtonActiveTextColor:$primaryTextColor;
$toggleButtonActiveIconColor:$primaryTextColor;
$toggleButtonActiveHoverBgColor:$primaryDarkColor;
$toggleButtonActiveHoverBorderColor:$primaryDarkColor;
$toggleButtonActiveHoverTextColor:$primaryTextColor;
$toggleButtonActiveHoverIconColor:$primaryTextColor;
$toggleButtonFocusOutline:0 none;
$toggleButtonFocusBgColor: $light-grey-background-two;
$toggleButtonFocusBorderColor: $light-grey-background-two;
$toggleButtonFocusTextColor: $charcoal;
$toggleButtonFocusIconColor: $navy;
$toggleButtonActiveFocusBgColor:$primaryDarkerColor;
$toggleButtonActiveFocusBorderColor:$primaryDarkerColor;
$toggleButtonActiveFocusTextColor:$primaryTextColor;
$toggleButtonActiveFocusIconColor:$primaryTextColor;

//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor: $light-grey-background-two;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
$ratingTransition:color $transitionDuration;
$ratingCancelIconColor:$CTA-light-cyan;
$ratingCancelHoverIconColor:$primaryColor;
$ratingIconFontSize:20px;
$ratingStarIconColor:$textColor;
$ratingStarIconHoverColor:$primaryColor;

//slider
$sliderBgColor: $light-grey-background-two;
$sliderBorder:0 none;
$sliderHeight:.286em;
$sliderWidth:0.286em;
$sliderHandleWidth:1.143em;
$sliderHandleHeight:1.143em;
$sliderHandleBgColor: $white;
$sliderHandleBorder:2px solid $grey;
$sliderHandleBorderRadius:100%;
$sliderHandleHoverBorder:2px solid $primaryColor;
$sliderHandleHoverBgColor:2px solid $grey;
$sliderHandleTransition:border-color $transitionDuration;
$sliderRangeBgColor:$primaryColor;

//calendar
$calendarWidth:20em;
$calendarNavIconColor: $light-grey;
$calendarNavIconHoverColor:$primaryColor;
$calendarNavIconTransition:color $transitionDuration;
$calendarPadding:0.857em;
$calendarTableMargin:0.857em 0 0 0;
$calendarHeaderCellPadding:.5em;
$calendarCellDatePadding:.5em;
$calendarCellDateHoverBgColor: $light-grey;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateSelectedBgColor:$primaryColor;
$calendarCellDateSelectedTextColor:$primaryTextColor;
$calendarCellDateTodayBgColor: $light-grey-background-two;
$calendarCellDateTodayTextColor:$textColor;
$calendarTimePickerDivider: 1px solid $grey;
$calendarTimePickerPadding:0.857em;
$calendarTimePickerIconColor:$textColor;
$calendarTimePickerIconFontSize:1.286em;
$calendarTimePickerTimeFontSize:1.286em;
$calendarTimePickerIconHoverColor:$primaryColor;
$calendarButtonBarDivider: 1px solid $light-grey;
$calendarMultipleMonthDivider: 1px solid $light-grey;

//spinner
$spinnerButtonWidth:2em;

//input switch
$inputSwitchWidth:3em;
$inputSwitchHeight:1.75em;
$inputSwitchBorderRadius:30px;
$inputSwitchTransition:background-color $transitionDuration;
$inputSwitchSliderOffBgColor: $light-grey-background-two;
$inputSwitchHandleOffBgColor: $white;
$inputSwitchSliderOffHoverBgColor: $light-grey;
$inputSwitchSliderOffFocusBgColor: $light-grey;
$inputSwitchSliderOnBgColor:$primaryColor;
$inputSwitchSliderOnHoverBgColor:$primaryDarkColor;
$inputSwitchHandleOnBgColor: $light-grey;
$inputSwitchSliderOnFocusBgColor:$primaryDarkerColor;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder:1px solid $light-grey;
$panelHeaderBgColor: $light-grey-background-two;
$panelHeaderTextColor: $ninety-percent-navy;
$panelHeaderIconColor:$ninety-percent-navy;
$panelHeaderIconHoverColor: $charcoal;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:400;
$panelHeaderPadding:0.571em 1em;
$panelContentBorder:1px solid $light-grey;
$panelContentBgColor: $light-grey-background-two;
$panelContentTextColor:$textColor;
$panelContentPadding:0.571em 1em;
$panelFooterBorder:1px solid $light-grey;
$panelFooterBgColor: $white;
$panelFooterTextColor:$textColor;
$panelFooterPadding:0.571em 1em;
$panelHeaderHoverBgColor: $light-grey-background-two;
$panelHeaderHoverBorder:1px solid $light-grey;
$panelHeaderHoverTextColor: $charcoal;
$panelHeaderHoverIconColor: $charcoal;
$panelHeaderActiveBgColor:$primaryDarkerColor;
$panelHeaderActiveBorder:1px solid $grey;
$panelHeaderActiveTextColor:$primaryTextColor;
$panelHeaderActiveIconColor:$primaryTextColor;
$panelHeaderActiveHoverBgColor:$primaryDarkerColor;
$panelHeaderActiveHoverBorder:1px solid $grey;
$panelHeaderActiveHoverTextColor:$primaryTextColor;
$panelHeaderActiveHoverIconColor:$primaryTextColor;
$panelHeaderTransition:background-color $transitionDuration;

//accordion
$accordionSpacing:2px;

//tabview
$tabsNavBorder:0 none;
$tabsNavBgColor:transparent;
$tabHeaderSpacing: 2px;

//scrollpanel
$scrollPanelHandleBgColor: $light-grey-background-two;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBgColor:$light-grey-background-one;

//card
$cardShadow:0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

//paginator
$paginatorBgColor: $white;
$paginatorBorder:1px solid $light-grey;
$paginatorPadding:0;
$paginatorIconColor:$textSecondaryColor;
$paginatorElementWidth:2.286em;
$paginatorElementHeight:2.286em;
$paginatorElementHoverBgColor: $light-grey-background-two;
$paginatorElementHoverIconColor: $charcoal;
$paginatorElementBorderRadius:0;
$paginatorElementMargin:0;
$paginatorElementPadding:0;
$paginatorElementBorder:0 none;

//table
$tableCaptionFontWeight:700;
$tableSummaryFontWeight:700;
$tableHeaderCellPadding:0.571em 0.857em;
$tableHeaderCellBgColor:$light-grey-background-two;
$tableHeaderCellTextColor:$textColor;
$tableHeaderCellFontWeight:700;
$tableHeaderCellBorder:1px solid $light-grey;
$tableHeaderCellHoverBgColor:$light-grey-background-one;
$tableHeaderCellHoverTextColor:$charcoal;
$tableHeaderCellIconColor:$textSecondaryColor;
$tableHeaderCellHoverIconColor:$charcoal;
$tableBodyRowBgColor:$white;
$tableBodyRowTextColor:$textColor;
$tableBodyRowEvenBgColor:$light-grey-background-one;
$tableBodyRowHoverBgColor:$light-grey;
$tableBodyRowHoverTextColor:$ninety-percent-navy;
$tableBodyCellBorder:1px solid $light-grey;
$tableBodyCellPadding:none;
$tableFooterCellPadding:none;
$tableFooterCellBgColor:$white;
$tableFooterCellTextColor:$textColor;
$tableFooterCellFontWeight:700;
$tableFooterCellBorder:1px solid $light-grey;
$tableResizerHelperBgColor:$primaryColor;

//schedule
$scheduleEventBgColor:$primaryDarkColor;
$scheduleEventBorder:1px solid $primaryDarkColor;
$scheduleEventTextColor:$primaryTextColor;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:$primaryTextColor;

//org chart
$organizationChartConnectorColor: $light-grey-background-two;

//messages
$messagesMargin:1em 0;
$messagesPadding:1em;
$messagesIconFontSize:2em;
$messageCloseIconFontSize:1.5em;

//message
$messagePadding:.429em;
$messageMargin: 0;
$messageIconFontSize: 1.25em;
$messageTextFontSize: 1em;

//toast
$toastShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$toastMessageMargin:0 0 1em 0;

//severities
$infoMessageBgColor:lighten($CTA-light-cyan, 25%);
$infoMessageBorder:0 none;
$infoMessageTextColor: $navy;
$infoMessageIconColor: $navy;
$successMessageBgColor: lighten($successButtonBgColor, 60%);
$successMessageBorder:0 none;
$successMessageTextColor: $navy;
$successMessageIconColor: $navy;
$warnMessageBgColor: lighten($warningButtonBgColor, 50%);
$warnMessageBorder:0 none;
$warnMessageTextColor: $navy;
$warnMessageIconColor: $navy;
$errorMessageBgColor:lighten($dangerButtonBgColor, 45%);
$errorMessageBorder:0 none;
$errorMessageTextColor: $navy;
$errorMessageIconColor: $navy;

//growl
$growlTopLocation:70px;
$growlIconFontSize:2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//overlays
$overlayContentBorderColor: $light-grey-background-two;
$overlayContentBorder:1px solid $overlayContentBorderColor;
$overlayContainerShadow:0 0 6px 0 rgba(0, 0, 0, 0.16);

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1.5em;

//overlay panel
$overlayPanelCloseIconBgColor:$primaryColor;
$overlayPanelCloseIconColor:$primaryTextColor;
$overlayPanelCloseIconHoverBgColor:$primaryDarkerColor;
$overlayPanelCloseIconHoverColor:$primaryTextColor;

//tooltip
$tooltipBgColor: $navy;
$tooltipTextColor:$primaryTextColor;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBgColor: $white;
$stepsItemBorder:1px solid $grey;
$stepsItemNumberColor:$textColor;
$stepsItemTextColor: $textColor;
$stepsItemWidth:28px;
$stepsItemHeight:28px;

//progressbar
$progressBarHeight:24px;
$progressBarBorder:0 none;
$progressBarBgColor: $light-grey;
$progressBarValueBgColor:$primaryDarkColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuBgColor:$primaryDarkerColor;
$menuBorder:1px solid $navy;
$menuPadding:0;
$menuTextColor:$primaryTextColor;
$menuitemPadding:1em 1.25em;
$menuitemMargin:0;
$menuitemTextColor:$primaryTextColor;
$menuitemIconColor:$primaryTextColor;
$menuitemHoverTextColor:$primaryTextColor;
$menuitemHoverIconColor:$primaryTextColor;
$menuitemHoverBgColor: $navy;
$menuitemActiveTextColor:$primaryTextColor;
$menuitemActiveIconColor:$primaryTextColor;
$menuitemActiveBgColor: $navy;
$submenuHeaderMargin: 0;
$submenuPadding: 0;
$overlayMenuBorder:1px solid $light-grey-background-two;
$overlayMenuShadow:0 0px 6px 0 rgba(0, 0, 0, 0.16);

//misc
$maskBgColor:rgba(0, 0, 0, 0.4);        //dialog mask
$inlineSpacing:.5em;                      //spacing between inline elements
$chipsItemMargin:0 .286em 0 0;            //autocomplete and chips token spacing
$dataIconColor:$textSecondaryColor;       //icon color of a data such as treetoggler, table expander
$dataIconHoverColor:$textColor;           //hover icon color of a data such as treetoggler, table expander

//general
$disabledOpacity:.5;                      //opacity of a disabled item