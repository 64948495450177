/* Prime Designer version 7.0.1 */
// designer overrides

body .ui-dataview .ui-dataview-content{
	padding: 0;
}
body .ui-table .ui-menu {
	background-color: $white;
	.ui-menuitem-link .ui-menuitem-text{
		color: $textColor;
	}
	.ui-menuitem-link.ui-state-active .ui-menuitem-text,
	.ui-menuitem-link:hover .ui-menuitem-text{
		color: $menuitemTextColor;
	}
	.ui-menuitem-link:hover {
		background-color: $primaryDarkerColor;
	}
}

// override for border properties in list view
@media screen and (max-width: 40em){
	body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
		border: $tableBodyCellBorder;
	}
}

