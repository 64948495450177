/*Layout*/

// Margin for all headers
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0 0 .5em;
}
// Margin for headers
h1, .h1, h2, .h2 {
	margin-bottom:.41666em;
}
// Margin for paragraph
p{
	margin: 0 0 .625em;
}
hr{
	margin-bottom: 1em;
}
// app-content margin
#app-content{
	flex-grow: 2;
	margin: 0 1.5em 1em;
}









/*Theme*/

// Headers fonts
h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
	font-family: $fontFamilyFordAntennaCond;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
}

// Certification details color and font
h3, .h3 {
	color: #777;
	font-size: 1.18rem;
}
// Booking confirmation number color and font-size
h4, .h4 {
	color: $navy;
	font-size: 1rem;
}
// Departing color
h5, .h5 {
	color: $grey;
}
// Not using h6 anywhere
h5, h6, .h5, .h6 {
	font-family: $fontFamilyFordAntenna;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
}
a:hover{
	color: $navy;
}
// Borders for search booking page
hr{
	border: 0;
	border-top: 1px solid $light-grey-background-two;
}
// Ford JAB Agent App h1 font family
#app-branding h1 {
	font-family: $fontFamilyFordAntenna;
	font-style: $font-style-normal;
	font-weight: $font-weight-normal;
}