body {
    .ui-widget-overlay {
        background-color: $maskBgColor;
    }

    .ui-overlaypanel {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: 0;
        border: $overlayContentBorder;
        @include border-radius(0);

        .ui-overlaypanel-content {
            padding: $panelContentPadding;
        }

        .ui-overlaypanel-close {
            background-color: $overlayPanelCloseIconBgColor;
            color: $overlayPanelCloseIconColor;
            width: 1.538em;
            height: 1.538em;
            line-height: 1.538em;
            text-align: center;
            position: absolute;
            top: -1 * 1.538em / 2;
            right: -1 * 1.538em / 2;
            @include transition(background-color $transitionDuration);

            &:hover {
                background-color: $overlayPanelCloseIconHoverBgColor;
                color: $overlayPanelCloseIconHoverColor;
            }

            .ui-overlaypanel-close-icon {
                line-height: inherit;
            }
        }

        &:after {
            border-color: rgba($panelContentBgColor, 0);
            border-bottom-color: $panelContentBgColor;
        }

        &:before {
            border-color: rgba($overlayContentBorderColor, 0);
            border-bottom-color: $overlayContentBorderColor;
        }

        &.ui-overlaypanel-flipped {
            &:after {
                border-top-color: $panelContentBgColor;
            }

            &:before {
                border-top-color: $overlayContentBorderColor;
            }
        }
    }

    .ui-dialog {
        padding: 0;
        @include shadow($overlayContainerShadow);

        .ui-dialog-titlebar {
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            padding: $dialogHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;

            .ui-dialog-title {
                margin: 0;
                float: none;
            }

            .ui-dialog-titlebar-icon {
                color: $panelHeaderIconColor;
                border: 0 none;
                padding: 0;
                margin-left: $inlineSpacing;
                @include transition(color $transitionDuration);

                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .ui-dialog-content {
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: $overlayContentBorder;
            padding: $panelContentPadding;
        }

        .ui-dialog-footer {
            border: $panelFooterBorder;
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            padding: $panelFooterPadding;
            margin: 0;
            text-align: right;
            position: relative;
            top: -1px;

            button {
                margin: 0 $inlineSpacing 0 0;
                width: auto;
            }
        }

        &.ui-confirm-dialog {
            .ui-dialog-content {
                padding: $confirmDialogPadding;

                > span {
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: $fontSize;
                    margin: 0;

                    &.ui-icon {
                        margin-right: .35em;
                        font-size: $fontSize + 2;
                    }
                }
            }
        }
    }

    .ui-sidebar {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;
        border: $overlayContentBorder;
        @include shadow($overlayContainerShadow);

        .ui-sidebar-close {
            color: $textSecondaryColor;

            &:hover {
                color: $textColor;
            }
        }
    }

    .ui-tooltip {
        .ui-tooltip-text {
            background-color: $tooltipBgColor;
            color: $tooltipTextColor;
            padding: $tooltipPadding;
            @include shadow($overlayContainerShadow);
        }

        &.ui-tooltip-right {
            .ui-tooltip-arrow {
                border-right-color: $tooltipBgColor;
            }
        }

        &.ui-tooltip-left {
            .ui-tooltip-arrow {
                border-left-color: $tooltipBgColor;
            }
        }

        &.ui-tooltip-top {
            .ui-tooltip-arrow {
                border-top-color: $tooltipBgColor;
            }
        }

        &.ui-tooltip-bottom {
            .ui-tooltip-arrow {
                border-bottom-color: $tooltipBgColor;
            }
        }
    }

    .ui-lightbox {
        @include shadow($overlayContainerShadow);

        .ui-lightbox-caption {
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;


            .ui-lightbox-caption-text {
                color: $panelHeaderTextColor;
                margin: 0;
            }

            .ui-lightbox-close {
                padding: 0;
                color: $panelHeaderIconColor;
                @include transition(color $transitionDuration);

                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .ui-lightbox-content-wrapper {
            overflow: hidden;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: 0 none;
            padding: 0;
            @include border-radius(0);

            .ui-lightbox-nav-left, .ui-lightbox-nav-right {
                @include transition(all $transitionDuration);
                font-size: $lightBoxNavIconFontSize;
                color: $lightBoxNavIconColor;
                margin-top: -.5em;

                &:hover {
                    @include scale(1.2);
                }
            }

            .ui-lightbox-content.ui-lightbox-loading ~ a {
                display: none;
            }
        }
    }
}
